import IconButton, { IconButtonProps } from '../IconButton';
import { useLingui } from '@lingui/react/macro';
import MoreIcon from '@watershed/icons/components/More';
import { IconElement } from '@watershed/icons/Icon';
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  gridClasses,
} from '../DataGrid/DataGrid';
import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import { ButtonProps } from '../Button';
import { mixinSx, tooltipDarkStyleProps } from '@watershed/style/styleUtils';
import DropdownMenu, { DropdownItem } from '../DropdownMenu';
import assertNever from '@watershed/shared-util/assertNever';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

type Variant = 'primary' | 'dangerous' | 'success' | 'warning';

export const OVERFLOW_MENU_CLASS_NAME = 'ObjectListOverflowMenu';

const BUTTON_SIZE = 32;

export function getOverflowMenuColumn<R extends GridValidRowModel>(
  getter: (params: GridRenderCellParams<R>) => ObjectListOverflowMenuProps
): GridColDef<R> {
  return {
    field: 'menu',
    type: 'actions',
    headerName: '',
    renderCell: (params) => <ObjectListOverflowMenu {...getter(params)} />,
    width: 56,
  };
}

export interface ObjectListOverflowMenuProps {
  dropdownItems?: Array<DropdownItem>;
  actions?: Array<OverflowActionButtonProps>;
}

const OVERFLOW_ACTION_ICON_BUTTON_PROPS = {
  tooltipPlacement: 'top',
  tooltipDisableInteractive: true,
} satisfies Partial<IconButtonProps>;

export function ObjectListOverflowMenu({
  dropdownItems,
  actions,
}: ObjectListOverflowMenuProps) {
  const { t } = useLingui();
  const hasDropdown = !!dropdownItems && dropdownItems.length > 0;
  const overflowActions = hasDropdown
    ? (actions ?? [])
    : (actions?.slice(0, -1) ?? []);
  const pinnedAction = hasDropdown ? undefined : actions?.at(-1);

  return (
    <Stack
      direction="row"
      className={OVERFLOW_MENU_CLASS_NAME}
      sx={{
        position: 'relative',
        // Set dimensions here too, because when there's _just_ the overflow
        // buttons, which sit in an absolutely positioned Stack, in order for
        // the positioning to be right, we need to preserve the dimensions.
        height: BUTTON_SIZE,
        width: BUTTON_SIZE,
      }}
    >
      <Stack
        direction="row"
        sx={{
          position: 'absolute',
          top: -5,
          right: -5,
          height: 42,
          padding: 0.5,
          gap: 0.25,
          paddingRight: '36px',
          borderRadius: '8px',
          opacity: 0,
          pointerEvents: 'none',
          transition: (theme) => theme.transitions.create(['opacity']),
          cursor: 'auto',
          // Only add overlay styling if there are additional items
          ':has(*)': {
            border: '1px solid transparent',
            borderColor: (theme) => theme.palette.divider,
            backgroundColor: (theme) => theme.palette.white,
            boxShadow:
              '0px 1px 2px 0px rgba(79, 89, 110, 0.16), 0px 0px 16px 1px rgba(79, 89, 110, 0.06)',
            [`.${gridClasses.row}:hover &, .${gridClasses.cell}:focus-within &`]:
              {
                opacity: 1,
              },
          },
        }}
      >
        {overflowActions.map((actionProps, index) => (
          <OverflowMenuIconButton key={index} {...actionProps} />
        ))}
      </Stack>
      {pinnedAction && <OverflowMenuIconButton {...pinnedAction} />}
      {hasDropdown && (
        <DropdownMenu
          items={dropdownItems}
          // Rely on the fact that the default `<DropdownMenu>` trigger is itself an `<IconButton>`
          triggerEndIcon={<MoreIcon size={16} />}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          tooltip={t({
            message: 'More actions',
            context: 'Tooltip for actions overflow menu on object lists',
          })}
          tooltipStyle="dark"
          buttonProps={{
            ...OVERFLOW_ACTION_ICON_BUTTON_PROPS,
            sx: [
              {
                width: BUTTON_SIZE,
                height: BUTTON_SIZE,
              },
            ],
          }}
        />
      )}
    </Stack>
  );
}

export type OverflowActionButtonProps = {
  IconElement: IconElement;
  tooltip?: LocalizedString;
  variant?: Variant;
  testId?: string;
  // we disable onSelect because it does nothing for <button> elements
  // but this is a different API than the dropdown, which is confusing
  // therefore this will just throw an error if you try to use it
} & Omit<ButtonProps, 'children' | 'color' | 'onSelect' | 'variant'>;

function useGetColor(variant: Variant | undefined, disabled?: boolean) {
  const theme = useTheme();
  if (!variant) {
    return undefined;
  }
  switch (variant) {
    case 'primary':
      if (disabled) return theme.palette.primary.light;
      return theme.palette.primary.main;
    case 'dangerous':
      if (disabled) return theme.palette.error.light;
      return theme.palette.error.main;
    case 'success':
      if (disabled) return theme.palette.success.light;
      return theme.palette.success.main;
    case 'warning':
      if (disabled) return theme.palette.warning.light;
      return theme.palette.warning.main;
    default:
      assertNever(variant);
  }
}

function OverflowMenuIconButton({
  tooltip,
  IconElement,
  variant,
  ...props
}: OverflowActionButtonProps) {
  const iconColor = useGetColor(variant, props.disabled);
  return (
    // NOTE(@lachlanjc):
    // Using a manual Tooltip wrapper here instead of the `IconButton`'s
    // `Tooltip` prop, because otherwise the `aria-label` is set on a
    // wrapping div instead of the focusable element.
    <Tooltip
      slotProps={tooltipDarkStyleProps()}
      title={tooltip}
      placement={OVERFLOW_ACTION_ICON_BUTTON_PROPS.tooltipPlacement}
      disableInteractive={
        OVERFLOW_ACTION_ICON_BUTTON_PROPS.tooltipDisableInteractive
      }
    >
      <Box component="span" sx={{ pointerEvents: 'auto' }}>
        <IconButton
          {...props}
          sx={mixinSx(
            {
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
            },
            props.sx
          )}
          data-testid={props.testId ?? TestIds.OverflowActionButton}
        >
          <IconElement size={16} color={iconColor} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
